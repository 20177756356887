import { FeatureSwitches } from '@natomalabs/common/feature_switches';

/**
 * 🧚🧚🧚🧚 HEY! LISTEN! 🧚🧚🧚🧚
 *
 * All code in this file will be evaluated server side, then returned to the frontend in a
 * resolved JSON object containing only the derived values.
 *
 * Any values here are public, even the ones not prefixed with `NEXT_PUBLIC_`.
 * These values are present in plaintext in the bundle sent to your browser.
 *
 * We should not be putting any secrets in the frontend anywhere in general, as
 * these will always be subject to the old "inspect element".
 *
 * These are public keys, values, or configuration settings that we would be
 * fine to write in public, check in to source control, paste to a user, search
 * up on google, tell your mom, tell your cousin, tell our enemies, tell our
 * competitors, etc. etc. so on and so forth.
 *
 * If you are not sure if the value you have is secret, don't stick it here!
 *
 * Additionally, consider how changing these values via inspect-element in the
 * application could affect the behavior of the app.
 */
type FeatureSwitchKey = keyof typeof FeatureSwitches;
type FeatureSwitchValues = Record<FeatureSwitchKey, boolean>;

export type NatomaRuntimeConfig = {
  natoma: {
    environment?: string;
    rootDomain: string;
  };
  descope: {
    projectId: string;
    pollSessionTime: number;
  };
  datadog: {
    appId?: string;
    clientToken?: string;
    service?: string;
    version?: string;
  };
  featureSwitches: FeatureSwitchValues;
};

function getEnvFeatureSwitchValues(
  featureSwitchKey: FeatureSwitchKey
): boolean {
  const definition = FeatureSwitches[featureSwitchKey];
  if (definition === undefined) {
    throw new Error(`Feature switch ${featureSwitchKey} not found`);
  }
  if (process.env[definition.env]?.toLowerCase() === 'true') {
    return true;
  } else if (process.env[definition.env]?.toLowerCase() === 'false') {
    return false;
  } else {
    return definition.default;
  }
}

// Using reduce because Typescript barks at us.
const featureSwitchValues: FeatureSwitchValues = Object.keys(
  FeatureSwitches
).reduce((values, key) => {
  values[key as FeatureSwitchKey] = getEnvFeatureSwitchValues(
    key as FeatureSwitchKey
  );
  return values;
}, {} as FeatureSwitchValues);

/**
 * 🧚🧚🧚🧚 STILL LISTEN! 🧚🧚🧚🧚
 *
 * NO SECRET VALUES IN THIS OBJECT AT ALL! 🚫🔒
 */
const config: NatomaRuntimeConfig = {
  natoma: {
    environment: process.env.ENVIRONMENT,
    rootDomain: process.env.ROOT_DOMAIN!
  },
  descope: {
    projectId: process.env.DESCOPE_PROJECT_ID!,
    pollSessionTime: 2 * 60 * 1000
  },
  datadog: {
    appId: process.env.DD_APP_ID,
    clientToken: process.env.DD_CLIENT_TOKEN,
    service: process.env.DD_SERVICE,
    version: process.env.DD_VERSION
  },
  featureSwitches: featureSwitchValues
};

export default config;
