export const FeatureSwitches = {
  integrationEntra: {
    doc: 'Switch to enable or disable the Entra integration',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INTEGRATION_ENTRA'
  },
  integrationGitHub: {
    doc: 'Switch to enable or disable the Github integration',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INTEGRATION_GITHUB'
  },
  integrationGoogleWorkspace: {
    doc: 'Switch to enable or disable the Google Workspace integration',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INTEGRATION_GOOGLE_WORKSPACE'
  },
  integrationOkta: {
    doc: 'Switch to enable or disable the Okta integration',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INTEGRATION_OKTA'
  },
  integrationSalesforce: {
    doc: 'Switch to enable or disable the Salesforce integration',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INTEGRATION_SALESFORCE'
  },
  integrationSnowflake: {
    doc: 'Switch to enable or disable the Snowflake integration',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INTEGRATION_SNOWFLAKE'
  },
  integrationSlack: {
    doc: 'Switch to enable or disable the Slack integration',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INTEGRATION_SLACK'
  },
  integrationAWS: {
    doc: 'Enables the ASYNC_CONFIGURATION auth type in AWS',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_AWS'
  },
  integrationAD: {
    doc: 'Enables the ASYNC_CONFIGURATION integration flow for Active Directory',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INTEGRATION_AD'
  },
  integrationJira: {
    doc: 'Switch to enable or disable the Jira integration',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INTEGRATION_JIRA'
  },
  integrationServiceNow: {
    doc: 'Switch to enable or disable the Service Now integration',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INTEGRATION_SERVICE_NOW'
  },
  ingestActivities: {
    doc: 'Enables ingestion of activity entities from integrations',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INGEST_ACTIVITIES'
  },
  processActivities: {
    doc: 'Enables updating related entities (service identities and resources) by processing saved activities',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_PROCESS_ACTIVITIES'
  },
  processActivitiesCreate: {
    doc: 'Enables creating related entities (service identities and resources) by processing saved activities',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_PROCESS_ACTIVITIES_CREATE'
  },
  processActivitiesUsage: {
    doc: 'Enables updating usage data of authenticators by processing activities',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_PROCESS_ACTIVITIES_USAGE'
  },
  processAWSRoleActivity: {
    doc: 'Enables updating usage data of roles by processing AWS activities',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_PROCESS_AWS_ROLE_ACTIVITY'
  },
  processAWSTransformToAuthResources: {
    doc: 'Enables creating authresource transform in AWSActivityProcessor',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_PROCESS_AWS_TRANSFORM_TO_AUTHRESOURCES'
  },
  jobChain: {
    doc: 'Enables creating jobs via job chain',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_JOB_CHAIN'
  },
  ingestAuthResources: {
    doc: 'Enables ingestion of authResources from integrations',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_INGEST_AUTHRESOURCES'
  },
  skipLatestActivityCheck: {
    doc: '[TESTING] Causes the activity ingest to skip a lookup for a latest activity timestamp and pull the default initial ingest window',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_SKIP_LATEST_ACTIVITY_CHECK'
  },
  ingestSalesforceMetadata: {
    doc: 'Enables ingestion of Salesforce Metadata augmentation',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INGEST_SALESFORCE_METADATA'
  },
  ingestGoogleWorkspaceService: {
    doc: 'Enables ingestion of Google Workspace 3P services',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INGEST_GOOGLE_WORKSPACE_SERVICE'
  },
  ingestOktaOIN: {
    doc: 'Enables ingestion of Okta API Service Integrations from OIN',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_INGEST_OKTA_OIN'
  },
  createAlerts: {
    doc: 'Enables alert creation',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_CREATE_ALERTS'
  },
  createRoleAlerts: {
    doc: 'Enables alert creation for roles',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_CREATE_ROLE_ALERTS'
  },
  createInferredServiceIdentities: {
    doc: 'Enables the creation of inferred service identities from inferred services',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_CREATE_INFERRED_SERVICE_IDENTITIES'
  },
  displayInferredServiceIdentities: {
    doc: 'Display inferred service identities in the UI',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_DISPLAY_INFERRED_SERVICE_IDENTITIES'
  },
  displayAlerts: {
    doc: 'Display alerts',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_DISPLAY_ALERTS'
  },
  displayUsage: {
    doc: 'Display usage in directory detail and authenticator detail',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_DISPLAY_USAGE'
  },
  inferServiceIdentityDeletion: {
    doc: 'Infer that a service identity has been deleted based off the last time it was ingested',
    format: Boolean,
    default: false,
    env: 'INFER_SERVICE_IDENTITY_DELETION'
  },
  inferAuthResourceDeletion: {
    doc: 'Infer that an auth resource has been deleted based off the last time it was ingested',
    format: Boolean,
    default: true,
    env: 'INFER_AUTH_RESOURCE_DELETION'
  },
  enableEventSubscriber: {
    doc: 'Enable event subscriber service to process webhook requests',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_ENABLE_EVENT_SUBSCRIBER'
  },
  processOktaActivities: {
    doc: 'Enable Okta Activity Processor',
    format: Boolean,
    default: true,
    env: 'PROCESS_OKTA_ACTIVITIES'
  },
  processGoogleWorkspaceActivities: {
    doc: 'Enable Google Workspace Activity Processor',
    format: Boolean,
    default: true,
    env: 'PROCESS_GOOGLE_WORKSPACE_ACTIVITIES'
  },
  processActiveDirectoryActivities: {
    doc: 'Enable Active Directory Activity Processor',
    format: Boolean,
    default: true,
    env: 'PROCESS_ACTIVE_DIRECTORY_ACTIVITIES'
  },
  processSalesforceActivities: {
    doc: 'Enable Salesforce Activity Processor',
    format: Boolean,
    default: false,
    env: 'PROCESS_SALESFORCE_ACTIVITIES'
  },
  processSnowflakeActivities: {
    doc: 'Enable Snowflake Activity Processor',
    format: Boolean,
    default: false,
    env: 'PROCESS_SNOWFLAKE_ACTIVITIES'
  },
  processAWSActivities: {
    doc: 'Enable AWS Activity Processor',
    format: Boolean,
    default: true,
    env: 'PROCESS_AWS_ACTIVITIES'
  },
  processEntraActivities: {
    doc: 'Enable Entra Activity Processor',
    format: Boolean,
    default: false,
    env: 'PROCESS_ENTRA_ACTIVITIES'
  },
  processGithubActivities: {
    doc: 'Enable Github Activity Processor',
    format: Boolean,
    default: false,
    env: 'PROCESS_GITHUB_ACTIVITIES'
  },
  processSlackActivities: {
    doc: 'Enable Slack Activity Processor',
    format: Boolean,
    default: false,
    env: 'PROCESS_SLACK_ACTIVITIES'
  },
  disableOktaActivityIngest: {
    doc: 'Stop saving Okta Activities',
    format: Boolean,
    default: true,
    env: 'DISABLE_OKTA_ACTIVITY_INGEST'
  },
  disableGoogleWorkspaceActivityIngest: {
    doc: 'Stop saving Google Workspace Activities',
    format: Boolean,
    default: true,
    env: 'DISABLE_GOOGLE_WORKSPACE_ACTIVITY_INGEST'
  },
  disableActiveDirectoryActivityIngest: {
    doc: 'Stop saving Active Directory Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_ACTIVE_DIRECTORY_ACTIVITY_INGEST'
  },
  disableSalesforceActivityIngest: {
    doc: 'Stop saving Salesforce Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_SALESFORCE_ACTIVITY_INGEST'
  },
  disableSnowflakeActivityIngest: {
    doc: 'Stop saving Snowflake Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_SNOWFLAKE_ACTIVITY_INGEST'
  },
  disableAWSActivityIngest: {
    doc: 'Stop saving AWS Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_AWS_ACTIVITY_INGEST'
  },
  disableEntraActivityIngest: {
    doc: 'Stop saving Entra Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_ENTRA_ACTIVITY_INGEST'
  },
  disableGithubActivityIngest: {
    doc: 'Stop saving Github Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_GITHUB_ACTIVITY_INGEST'
  },
  disableSlackActivityIngest: {
    doc: 'Stop saving Slack Activities',
    format: Boolean,
    default: false,
    env: 'DISABLE_SLACK_ACTIVITY_INGEST'
  },
  displayDirectoryImprovements: {
    doc: 'Display Directory Improvements',
    format: Boolean,
    default: true,
    env: 'DISPLAY_DIRECTORY_IMPROVEMENTS'
  },
  displayDirectoryWidgets: {
    doc: 'Display Directory Widgets',
    format: Boolean,
    default: true,
    env: 'DISPLAY_DIRECTORY_WIDGETS'
  },
  ingestOktaAppSecrets: {
    doc: 'Ingest Okta App JWKs and Client Secrets',
    format: Boolean,
    default: true,
    env: 'INGEST_OKTA_SECRETS'
  },
  enableWindowsEventForwarding: {
    doc: 'Conditionally sets AD Agent configs to read from the "Forwarded Events" Log',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_ENABLE_WEF'
  },
  displayInstanceNameFilterInDirectory: {
    doc: 'Display instance name filter in directory',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_DISPLAY_INSTANCE_NAME_FILTER_IN_DIRECTORY'
  },
  allowInProductDownloads: {
    doc: 'Allows users to download the latest version of the AD Agent in the product',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_IN_PRODUCT_DOWNLOADS'
  },
  hideAuthenticatorsWithoutPermissions: {
    doc: 'Hide authenticators in the UI that do not have permissions',
    format: Boolean,
    default: true,
    env: 'HIDE_UNPERMISSIONED_AUTHENTICATORS'
  },
  enableItsmTicketCreation: {
    doc: 'Enable ITSM Ticket creation based on alerts',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_ENABLE_ITSM_TICKET_CREATION'
  },
  enableGranularTypeBasedIdentityClassification: {
    doc: 'Enable identity classification based on granular type',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_ENABLE_GRANULAR_TYPE_CLASSIFICATION'
  },
  enableGranularTypeBasedIdentityClassificationRead: {
    doc: 'Enable read path for identity classification based on granular type',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_ENABLE_GRANULAR_TYPE_CLASSIFICATION_READ'
  },
  displayEntitlements: {
    doc: 'Display Entitlements tab under Account details',
    format: Boolean,
    default: true,
    env: 'DISPLAY_ENTITLEMENTS'
  },
  showServiceIdentityAttributes: {
    doc: 'Show (service specific) attributes for a ServiceIdentity',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_SHOW_SERVICE_IDENTITY_ATTRIBUTES'
  },
  logAuditEvents: {
    doc: 'Log audit events for the org',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_LOG_AUDIT_EVENTS'
  },
  showAuditLogEvents: {
    doc: 'Show audit log events in the app',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_LOG_AUDIT_EVENTS'
  },
  showDashboardView: {
    doc: 'Whether to show the frontend dashboard page in the React app.',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_SHOW_DASHBOARD_VIEW'
  },
  enableDpopForOkta: {
    doc: 'Whether DPoP (Demonstrating Proof of Possession) is enabled for Okta',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_ENABLE_DPOP_FOR_OKTA'
  },
  allowAuxiliaryService: {
    doc: 'Whether we allow the usage of auxiliary service in the org',
    format: Boolean,
    default: true,
    env: 'FEATURE_SWITCH_ALLOW_AUXILIARY_SERVICE'
  },
  displaySidebarLayoutNested: {
    doc: 'Display nested tabs in SidebarLayout',
    format: Boolean,
    default: true,
    env: 'DISPLAY_SIDEBAR_LAYOUT_NESTED'
  },
  displayEntitlementTableAndDetail: {
    doc: 'Display Entitlements Table and Detail',
    format: Boolean,
    default: true,
    env: 'DISPLAY_ENTITLEMENTS_TABLE_AND_DETAIL'
  },
  enableItsmWebhookEvents: {
    doc: 'Enable ITSM webhook events processing globally',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_ENABLE_ITSM_WEBHOOK_EVENTS'
  },
  showCampaignsForAdmins: {
    doc: 'Show Campaigns for admins',
    format: Boolean,
    default: false,
    env: 'FEATURE_SWITCH_SHOW_CAMPAIGNS_FOR_ADMINS'
  }
};

export type FeatureSwitchKey = keyof typeof FeatureSwitches;
