'use client';

import { redirect, useRouter, usePathname } from 'next/navigation';
import { LoginPath, SignUpPath, InvitePath, ErrorPath } from '../lib/paths';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import LoadingLayoutOverlay from './LoadingLayoutOverlay';
import { MeContext } from '../providers/Me';
import { useDescope, isSessionTokenExpired } from '@descope/react-sdk';
import useInterval from '../hooks/use_interval';
import config from '../lib/config';

/**
 * Handles redirecting the page if a user is logged-in or not.
 *
 * @param props
 * @returns
 */
export default function AuthGuard({
  children,
  layout
}: {
  children: ReactNode;
  layout: 'home' | 'tenant';
}) {
  const path = usePathname();
  const isLoginOrSignUpPage =
    path === LoginPath ||
    path === SignUpPath ||
    path === InvitePath ||
    path === ErrorPath;

  const { loading, isAuthenticated, isAuthorized } = useContext(MeContext);
  const router = useRouter();
  const { getSessionToken } = useDescope();

  // poll to check session validity and redirect to login if invalid
  useInterval(() => {
    if (!isLoginOrSignUpPage) {
      const sessionToken = getSessionToken();
      if (!sessionToken || isSessionTokenExpired(sessionToken)) {
        router.push(LoginPath);
      }
    }
  }, config.descope.pollSessionTime);

  const allowed = layout === 'tenant' ? isAuthorized : isAuthenticated;
  if (loading) {
    return <LoadingLayoutOverlay />;
  } else if (isLoginOrSignUpPage) {
    if (allowed) {
      redirect('/');
    } else {
      return <>{children}</>;
    }
  } else {
    if (allowed) {
      return <>{children}</>;
    } else {
      redirect(LoginPath);
    }
  }
}
