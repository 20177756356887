import { useEffect, useMemo } from 'react';

/**
 * Custom hook that sets up an interval to repeatedly call a function.
 *
 * @param fn - The function to be called at each interval.
 * @param timeout - The time (in milliseconds) between each function call.
 */

function useInterval(fn: () => void, timeout: number) {
  const interval = useMemo(() => setInterval(fn, timeout), [fn, timeout]);

  useEffect(() => {
    return () => clearInterval(interval);
  }, [interval]);
}

export default useInterval;
